import React from 'react'
import Img from 'gatsby-image'
import Button from '../Ui/Button'
import Fade from 'react-reveal/Fade'

import poster from '../../images/posters/content-blocks-call-to-action.jpg'

const ContentBlocksCallToAction = ({
  title,
  text,
  image,
  alt,
  link,
  linkText,
  reversed,
  ghost,
}) => {
  let ctaLink
  if (link === 'home') {
    ctaLink = ''
  } else {
    ctaLink = link
  }
  return (
    <div className={reversed ? 'lg:gutters' : ''}>
      <div className="relative">
        <Fade delay={225} duration={2250}>
          {image ? (
            <div>
              <Img fluid={image.localFile.childImageSharp.fluid} alt={alt} />
            </div>
          ) : (
            <div className="ratio ratio--2/3 relative z-10">
              <div
                className="ratio__content"
                dangerouslySetInnerHTML={{
                  __html: `
                <video
                  class="w-full h-full object-cover"
                  muted
                  autoplay
                  playsinline
                  loop
                  poster="${poster}"
                  src="https://player.vimeo.com/external/369072798.hd.mp4?s=828cf1d0d9cd52f2ca55786ebd1d480fea83b6ff&profile_id=174"
                />`,
                }}
              />
            </div>
          )}
        </Fade>
        <div
          className={`${
            reversed ? 'gutters lg:gutters-none justify-end' : 'gutters'
          } relative lg:absolute inset-0 flex items-center z-20 -mt-10 lg:mt-0`}
        >
          <div
            className={`${
              ghost ? 'bg-blue-800 sm:bg-transparent lg:ml-1/24' : 'bg-blue-800'
            } w-full lg:w-1/2 xl:w-8/24 py-10 lg:py-18`}
          >
            <Fade delay={450} duration={2250}>
              <div className="mx-1/12 lg:mx-1/8">
                {title && (
                  <h3 className="text-xs uppercase tracking-wider mb-6 lg:mb-10">
                    {title}
                  </h3>
                )}
                {text && (
                  <div
                    className={`font-light mb-8 rte md:text-lg ${
                      ghost ? 'md:text-2xl' : ''
                    }`}
                    dangerouslySetInnerHTML={{ __html: text.html }}
                  ></div>
                )}

                {link && <Button link={ctaLink} linkText={linkText} />}
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentBlocksCallToAction
