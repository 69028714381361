import React from 'react'
import Img from 'gatsby-image'

class SustainabilityCompact extends React.Component {
  render() {
    const { items } = this.props
    const validItems = items.filter(
      ({ title, text, image }) => title && text && image
    )
    return (
      <div className="gutters">
        {validItems.map((item, index) => (
          <div key={index} className="mb-20">
            <div className="flex justify-between text-xl sm:text-3xl mb-4 ">
              <div>0{index + 1}</div>
              <div>{item.title}</div>
            </div>
            <div className="w-full h-px bg-white-opaque-50 mb-10"></div>
            <div className="mb-10">
              <Img
                fluid={item.image.localFile.childImageSharp.fluid}
                alt={item.image.alt}
              />
              {item.image.alt && (
                <p className="text-sm font-light mt-4">{item.image.alt}</p>
              )}
            </div>
            <div className="w-11/12">
              <div
                className="rte font-light"
                dangerouslySetInnerHTML={{ __html: item.text.html }}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default SustainabilityCompact
