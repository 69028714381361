import React from 'react'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

import poster from '../../images/posters/content-blocks-default.jpg'

const TextImage = ({ title, text, image, video, reversed }) => {
  const classList = () => {
    let theClassList = ''
    if (reversed) {
      theClassList = 'mr-1/12 md:ml-1/12 md:mr-4/12 lg:mx-1/9 '
    } else if (video) {
      theClassList = 'mx-1/12 md:ml-2/12 md:mr-3/12 lg:mx-1/9'
    } else {
      theClassList = 'mx-1/12 md:ml-2/12 md:mr-3/12 lg:mx-1/9'
    }
    return theClassList
  }

  return (
    <div className="gutters">
      <div
        className={`flex flex-col lg:flex-row ${
          reversed || video ? ' lg:flex-row-reverse' : ''
        }`}
      >
        {image && (
          <Fade delay={225} duration={2250}>
            <div className="lg:w-15/24 mb-16 lg:mb-0">
              <div className="flush-both lg:flush-none">
                <Img
                  fluid={image.localFile.childImageSharp.fluid}
                  alt={image.alt}
                />
                {image.alt && (
                  <p className="text-sm font-light mt-4 gutters lg:gutters-none">
                    {image.alt}
                  </p>
                )}
              </div>
            </div>
          </Fade>
        )}
        {video && (
          <Fade delay={225} duration={2250}>
            <div className="lg:w-15/24 mb-16 lg:mb-0 md:ml-2/12 lg:ml-0">
              <div className="flush-both md:flush-right">
                <div className="ratio ratio--2/3">
                  <div
                    className="ratio__content"
                    dangerouslySetInnerHTML={{
                      __html: `
                    <video
                      class="w-full h-full object-cover"
                      muted
                      autoplay
                      playsinline
                      loop
                      poster="${poster}"
                      src="https://player.vimeo.com/external/369072459.hd.mp4?s=646edbd7d1af2fb0c77a1038f2aab2aac1df0dc2&profile_id=174"
                    />`,
                    }}
                  />
                </div>
              </div>
            </div>
          </Fade>
        )}
        <div className="lg:w-9/24 flex items-center justify-center">
          <Fade delay={450} duration={2250}>
            <div className={classList()}>
              {title && (
                <h3 className="text-xs tracking-wider uppercase mb-8 lg:mb-10">
                  {title}
                </h3>
              )}
              <div
                className="rte text-lg font-light"
                dangerouslySetInnerHTML={{ __html: text.html }}
              ></div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default TextImage
