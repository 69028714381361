import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
import ScrollIndicator from '../components/Ui/ScrollIndicator'
import Footer from '../components/Footer'
import ContentBlocksIntroduction from '../components/ContentBlocks/ContentBlocksIntroduction'
import ContentBlocksDefault from '../components/ContentBlocks/ContentBlocksDefault'
import ContentBlocksCallToAction from '../components/ContentBlocks/ContentBlocksCallToAction'
import KawateaSustainability from '../components/Kawatea/KawateaSustainability'
import KawateaSustainabilityCompact from '../components/Kawatea/KawateaSustainabilityCompact'

const footerLinks = ['traceability', 'pot-fishing', 'past']

const TheKawateaPage = ({ location, data }) => {
  const page = data.prismicKawatea.data

  const sources = [
    data.mobileHeroImage.childImageSharp.fluid,
    {
      ...data.heroImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const sustainabilityItems = [
    {
      title: page.primary_title,
      text: page.primary_text,
      image: page.primary_image,
    },
    {
      title: page.secondary_title,
      text: page.secondary_text,
      image: page.secondary_image,
    },
    {
      title: page.tertiary_title,
      text: page.tertiary_text,
      image: page.tertiary_image,
    },
    {
      title: page.quaternary_title,
      text: page.quaternary_text,
      image: page.quaternary_image,
    },
  ]
  return (
    <Layout>
      <SEO title="The Kawatea" description="" location={location} />
      <ScrollIndicator />
      <Hero title="Kawatea" preTitle="Our Vessel" image={sources} scrollLink />
      <div id="discover" className="pt-48">
        <div className="mb-48">
          <ContentBlocksIntroduction title={'Our Vessel'} text={page.lead} />
        </div>
        <div className="mb-32">
          <ContentBlocksDefault
            text={page.main_primary_text}
            image={page.main_primary_image}
          />
        </div>
        <div className="mb-32">
          <ContentBlocksDefault text={page.main_secondary_text} video />
        </div>
        <div className="mb-32">
          <div className="hidden lg:block">
            <KawateaSustainability
              lead={page.sustainability_lead}
              items={sustainabilityItems}
            />
          </div>
          <div className="block lg:hidden">
            <KawateaSustainabilityCompact items={sustainabilityItems} />
          </div>
        </div>
        <div className="mb-32">
          <ContentBlocksIntroduction title={'Our Crew'} text={page.crew_lead} />
          <div className="w-10/12 mx-auto text-center hidden sm:block lg:hidden mt-10">
            {page.crew_text && (
              <div
                className="rte font-light text-lg"
                dangerouslySetInnerHTML={{ __html: page.crew_text.html }}
              ></div>
            )}
          </div>
        </div>
        <div className="mb-32">
          {page.crew_text && (
            <div
              className="rte font-light text-lg"
              dangerouslySetInnerHTML={{ __html: page.crew_text.html }}
            ></div>
          )}
          <div className="lg:w-20/24 xl:w-18/24 mx-auto gutters">
            <div className="flex flex-wrap -ml-5 lg:-ml-16 -pb-10">
              {page.crew_images.map((item, index) => (
                <div
                  className="w-full sm:w-1/2 pl-5 lg:pl-16 pb-10"
                  key={index}
                >
                  <Img
                    fluid={item.image.localFile.childImageSharp.fluid}
                    alt={item.image.alt}
                  />
                  {item.image.alt && (
                    <p className="text-sm font-light mt-4">{item.image.alt}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <ContentBlocksCallToAction
          text={{
            html: page.cta_text.html,
          }}
          link={page.cta_link.uid}
          linkText={page.cta_link_text}
          ghost
        />
      </div>
      <Footer links={footerLinks} />
    </Layout>
  )
}

TheKawateaPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageFutureQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero/kawatea.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileHeroImage: file(relativePath: { eq: "hero/mobile/kawatea.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismicKawatea {
      data {
        meta_description
        meta_title
        crew_images {
          image {
            alt
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 1030, maxHeight: 686) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        crew_lead {
          html
          text
        }
        crew_text {
          html
          text
        }
        lead {
          html
          text
        }
        sustainability_lead {
          html
          text
        }
        primary_title
        primary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1346, maxHeight: 894) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        primary_text {
          html
          text
        }
        secondary_title
        secondary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1346, maxHeight: 894) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        secondary_text {
          html
          text
        }
        tertiary_title
        tertiary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1346, maxHeight: 894) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tertiary_text {
          html
          text
        }
        quaternary_title
        quaternary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1346, maxHeight: 894) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        quaternary_text {
          html
          text
        }
        cta_link {
          uid
        }
        cta_text {
          html
        }
        cta_link_text
        main_secondary_text {
          html
          text
        }
        main_primary_text {
          html
          text
        }
        main_primary_image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1684, maxHeight: 1122) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default TheKawateaPage
