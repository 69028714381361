import React from 'react'
import gsap from 'gsap'
import Img from 'gatsby-image'

class Sustainability extends React.Component {
  constructor(props) {
    super(props)
    this.container = React.createRef()
    this.validItems = []
  }

  state = {
    length: 4,
    xPos: 0,
    scrollBuffer: 50,
  }

  eventScroll = () => {
    const { length } = this.state

    const containerRect = this.container.current.getBoundingClientRect()
    const x =
      ((window.scrollY - (containerRect.top + window.scrollY)) /
        (window.outerHeight * length)) *
      100

    if (containerRect.top >= 0) {
      this.setState({
        xPos: 0,
      })
    } else if (
      containerRect.top < 0 &&
      containerRect.bottom > window.outerHeight
    ) {
      this.setState({
        xPos: x,
      })
    } else if (containerRect.bottom <= window.outerHeight) {
      this.setState({
        xPos: (100 / length) * (length - 1),
      })
    }
  }

  eventTick = () => {
    const { xPos } = this.state
    gsap.to('.horizontal-scroll-container', {
      duration: 1,
      x: `-${xPos}%`,
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.eventScroll, false)
    gsap.ticker.add(this.eventTick)

    const { items } = this.props
    this.validItems = items.filter(
      ({ title, text, image }) => title && text && image
    )
    this.setState({ length: this.validItems.length })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.eventScroll, false)
    gsap.ticker.remove(this.eventTick)
  }

  render() {
    const { lead } = this.props
    const { length, scrollBuffer } = this.state
    return (
      <div className="relative w-full">
        <div style={{ height: `${scrollBuffer}vh` }}></div>
        <div ref={this.container} style={{ height: `${100 * length}vh` }}>
          <div
            className="absolute top-0 h-full"
            style={{ height: `${100 * length + scrollBuffer * 2}vh` }}
          >
            <div className="sticky top-0 h-screen w-screen overflow-hidden">
              <div className="flex flex-col h-full">
                <div className="w-screen gutters shorter:pt-hh pt-hh+ flex items-center short:mb-4 mb-4 xl:mb-14">
                  <div className="w-12/24">
                    <h3 className="short:text-5xl text-5xl xl:text-7xl font-light">
                      Sustainability
                    </h3>
                  </div>
                  <div className="hidden short:hidden xl:block w-6/24 ml-2/24">
                    <div
                      className="rte font-light text-2xl"
                      dangerouslySetInnerHTML={{ __html: lead.html }}
                    ></div>
                  </div>
                </div>
                <div
                  className="horizontal-scroll-container flex"
                  style={{ width: `${100 * length}vw` }}
                >
                  {this.validItems.map((item, index) => {
                    let flushClass
                    switch (index) {
                      case 0:
                        flushClass = 'flush-right'
                        break
                      case 3:
                        flushClass = 'flush-left'
                        break
                      default:
                        flushClass = 'flush-both'
                    }
                    return (
                      <div key={index} className="w-screen h-full gutters">
                        <div className="flex mb-4">
                          <div className="shorter:w-10/24 w-12/24">
                            <div className="font-light short:text-base text-base xl:text-2xl">
                              0{index + 1}
                            </div>
                          </div>
                          <div className="shorter:w-10/24 w-6/24 ml-2/24">
                            <div className="font-light short:text-base text-base xl:text-2xl">
                              {item.title}
                            </div>
                          </div>
                        </div>
                        <div className="w-full short:mb-8 mb-14">
                          <div
                            className={`h-px bg-white-opaque-50 ${flushClass}`}
                          ></div>
                        </div>
                        <div className="flex">
                          <div className="shorter:w-10/24 w-12/24">
                            <Img
                              fluid={item.image.localFile.childImageSharp.fluid}
                              alt={item.image.alt}
                            />
                            {item.image.alt && (
                              <p className="text-sm font-light mt-4">
                                {item.image.alt}
                              </p>
                            )}
                          </div>
                          <div className="shorter:w-10/24 w-10/24 xl:w-6/24 ml-2/24">
                            <div
                              className="rte font-light text-lg"
                              dangerouslySetInnerHTML={{
                                __html: item.text.html,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: `${scrollBuffer}vh` }}></div>
      </div>
    )
  }
}

export default Sustainability
